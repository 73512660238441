import React, { useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
// material
import { alpha, styled } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu';
import {
  Container,
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Link,
  Menu,
  MenuItem,
} from '@mui/material'
// components
import useMediaQuery from "@mui/material/useMediaQuery";
import Iconify from '../components/Iconify'

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 65

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.primary.lighter, 0.3),
  // backgroundColor: alpha(theme.palette.background.default, 0.72),
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_DESKTOP,
  [theme.breakpoints.down('md')]: {
    minHeight: APPBAR_MOBILE,
    padding: 0,
  },
}))

const SocialIcons = () => (
  <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
    {socialLinks.map((link, index) => (
      <IconButton key={index} onClick={() => window.open(link.url, '_blank')} sx={{ color: 'text.primary' }}>
        <Iconify icon={link.icon} />
      </IconButton>
    ))}
  </Stack>
);

const socialLinks = [
  { url: 'https://drive.google.com/file/d/152-5LtYD0sr3PunfvReca7KWjSnT7uTH/view?usp=sharing', icon: 'tabler:file-cv' },
  { url: 'https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=jeonghye.kim@kaist.ac.kr', icon: 'ic:round-mail' },
  { url: 'https://github.com/beanie00', icon: 'eva:github-fill' },
  { url: 'https://www.linkedin.com/in/beanie00', icon: 'eva:linkedin-fill' },
];

// ----------------------------------------------------------------------

export default function DashboardNavbar() {
  const location = useLocation()
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const { pathname } = location

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        {isMobile && <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: '4px',
              paddingRight: '4px'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <IconButton
                size="large"
                aria-label="open drawer"
                onClick={handleMenu}
                color="inherit"
              >
                <MenuIcon color="primary" />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* Map through an array to render menu items */}
                {['/', '/projects'].map((path, index) => (
                  <MenuItem key={index} onClick={handleClose}>
                    <Link underline="none" component={RouterLink} to={path}>
                      <Typography sx={{ color: pathname === path ? 'primary.main' : 'text.secondary' }}>
                        {path === '/' ? 'Home' : 'Dev Projects'}
                      </Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>

              <Link underline="none" component={RouterLink} to="/">
                <Box component="img" src="/static/common/beanie.png" sx={{ height: 25, width: '50px', objectFit: 'contain' }} />
              </Link>
            </Box>

            {/* Social Icons */}
            <SocialIcons />
        </Container>}

        {!isMobile && <Container
          maxWidth='md'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
            <Link underline="none" component={RouterLink} to="/">
              <Box component="img" src="/static/common/beanie.png" sx={{ height: 25, width: '50px', objectFit: 'contain' }} />
            </Link>

            <Stack
              sx={{ width: '100%', flexDirection: 'row', justifyContent: 'center', ml: '80px' }}
            >
              {['/', '/projects'].map((path, index) => (
                <Link key={index} underline="none" component={RouterLink} to={path}>
                  <Typography fontWeight={600} sx={{ color: pathname === path ? 'primary.main' : 'text.secondary', ml: index > 0 ? 5 : 0 }}>
                    {path === '/' ? 'Home' : 'Dev Projects'}
                  </Typography>
                </Link>
              ))}
            </Stack>

            {/* Social Icons */}
            <SocialIcons />
      </Container>}
      </ToolbarStyle>
    </RootStyle>
  )
}