import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import Iconify from 'components/Iconify'
import PropTypes from 'prop-types'

const iconStyles = ({ theme }) => ({
  width: '16px',
  fontSize: '1.1rem',
  [theme.breakpoints.down('md')]: {
    width: '13px',
  },
});

const Icon = styled(Iconify)(iconStyles);

const IconWrapper = styled('div')(iconStyles);

const AwardEntry = ({ date, description, link, linkText }) => (
  <>
    <Typography sx={{ fontSize: '0.9rem', color: 'grey.500', fontWeight: 'bold', pl: 0.5, mt: date === '2021.03' ? 0 : 1 }}>
      {date}
    </Typography>
    <Box sx={{ display: 'flex', alignItems: 'center', pl:0.5 }}>
      <IconWrapper>
        <Icon sx={{width: '13px'}} color='primary.main' icon='akar-icons:square-fill' />
      </IconWrapper>
      <Typography sx={{ fontSize: '1.1rem', ml: 1, fontWeight: 'bold' }}>
        {description}
      </Typography>
    </Box>
    <Typography sx={{ fontSize: '1rem', ml: 3.5, mb: description.includes('3rd place') ? 0 : 1 }}>
      news article : <a target='_blank' href={link} rel='noopener noreferrer'>{linkText || link}</a>
    </Typography>
  </>
);

AwardEntry.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};

export default function Awards() {
  const awards = [
    { date: '2021.03', description: 'Pre-Startup Package specialized area(AI) Excellence award', link: 'https://m.etnews.com/20210325000060' },
    { date: '2020.08', description: 'Selected for agriculture specialized accelerating program B.grow (16:1 competition rate)', link: 'https://platum.kr/archives/146526' },
    { date: '2020.05', description: 'KAIST startup challenge E*5 3rd place', link: 'https://startup.kaist.ac.kr/boards/view/board_interveiw/440', linkText: 'Interview @ Startup, KAIST' },
  ];

  return (
    <Box>
      {awards.map((award, index) => (
        <AwardEntry key={index} {...award} />
      ))}
    </Box>
  );
}