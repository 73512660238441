import React from 'react'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

export default function TeachingExperience({ isMobile }) {

  const teachingData = [
    { term: 'Fall 2023', role: 'TA, EE214 Machine Learning Basics and Practices, KAIST' },
    { term: 'Spring 2023', role: 'TA, EE619 Mathematical Foundations of Reinforcement Learning, KAIST' },
    { term: 'Fall 2022', role: 'TA, EE210 Probability and Introductory Random Processes, KAIST' },
    { term: 'Fall 2018', role: 'TA, CS101 Introduction to Programming, KAIST' },
    { term: '2016~2017', role: 'Student Tutor, MAS102 Calculus II, KAIST' },
  ];

  const textStyle = {
    fontSize: '0.9rem',
    color: 'grey.500',
    fontWeight: 'bold',
    width: '110px',
  };

  const TeachingItem = ({ term, role }) => (
    <Box sx={{ mt: term === 'Fall 2023' ? 0 : 1, pl: 0.5, display: !isMobile && 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Typography sx={textStyle}>
        {term}
      </Typography>
      <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
        {role}
      </Typography>
    </Box>
  );

  TeachingItem.propTypes = {
    term: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
  };

  return (
    <Box>
    {teachingData.map((item, index) => (
      <TeachingItem key={index} term={item.term} role={item.role} />
    ))}
  </Box>
  )
}

TeachingExperience.propTypes = {
  isMobile: PropTypes.bool.isRequired
}
