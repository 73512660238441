import React from 'react'
import { Box, Typography } from '@mui/material'

export default function News() {

  return (
    <Box>
      <Box sx={{ pl:0.5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: '1rem',
            color: 'grey.500',
            fontWeight: 'bold',
            mr: 4.9
          }}
        >
          Sep. 2024
        </Typography>
        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
          QCS has been accepted to NeurIPS 2024 and will be presented in Vancouver, Canada 🇨🇦
        </Typography>
      </Box>
      <Box sx={{ pl:0.5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: '1rem',
            color: 'grey.500',
            fontWeight: 'bold',
            mr: 5.2
          }}
        >
          Jan. 2024
        </Typography>
        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
          <a href="https://openreview.net/forum?id=af2c8EaKl8" target='_blank' rel="noreferrer">DC</a> has been accepted to ICLR 2024 as a spotlight presentation! See you in Vienna, Austria 🇦🇹
        </Typography>
      </Box>
      <Box sx={{ pl:0.5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: '1rem',
            color: 'grey.500',
            fontWeight: 'bold',
            mr: 5
          }}
        >
          Apr. 2023
        </Typography>
        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
          My first paper, <a href="https://openreview.net/forum?id=vXcvrYJlVm" target='_blank' rel="noreferrer"> LESSON</a>, has been accepted to ICML 2023! I hope to see you in Hawaii, USA 🇺🇸
        </Typography>
      </Box>
    </Box>
  )
}